@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;700&display=swap');


@font-face {
  font-family: "ABCSocial";
  src: url("fonts/ABCSocial-Regular.woff2") format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "ABCSocial";
  src: url("fonts/ABCSocial-RegularItalic.woff2") format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "ABCSocial";
  src: url("fonts/ABCSocial-Medium.woff2") format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "ABCSocial";
  src: url("fonts/ABCSocial-MediumItalic.woff2") format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "ABCSocial";
  src: url("fonts/ABCSocial-Bold.woff2") format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "ABCSocial";
  src: url("fonts/ABCSocial-BoldItalic.woff2") format('woff2');
  font-weight: 700;
  font-style: italic;
}



html, body {
  overflow-x: clip !important;
}

body {
  padding-right: 0 !important;
  overflow: clip !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-color: #fafaf5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

